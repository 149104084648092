import Config from "../Config";
import { RestService } from "./RestService";

export const LeadService = {
  getLeadsStatusCount: async function (
    status = null,
    fromDate = null,
    toDate = null,
    referral_ids = null
  ) {
    return RestService.get(
      `${Config.lead_status_count_url}?${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {}
    ).then((response) => response.json());
  },
  getLeadsCount: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.leads_url}?count=true${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getLeadsList: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    from = 0,
    offset = 20,
    order = null,
    direction = null,
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.leads_url}?${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${from ? `&from=${from}` : ""}${
        offset ? `&offset=${offset}` : ""
      }${order ? `&order=${order}` : ""}${
        direction ? `&direction=${direction}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getLeadsListExport: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    from = 0,
    offset = 20,
    order = null,
    direction = null,
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.leads_url}?export=true${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${from ? `&from=${from}` : ""}${
        offset ? `&offset=${offset}` : ""
      }${order ? `&order=${order}` : ""}${
        direction ? `&direction=${direction}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => {
          // console.log(data)
          // return data
          return Promise.reject();
        });
      } else {
        return response.blob();
      }
    });
  },
  getRekycLeadsCount: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.rekyc_leads_url}?count=true${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getRekycLeadsList: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    from = 0,
    offset = 20,
    order = null,
    direction = null,
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.rekyc_leads_url}?${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${from ? `&from=${from}` : ""}${
        offset ? `&offset=${offset}` : ""
      }${order ? `&order=${order}` : ""}${
        direction ? `&direction=${direction}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getRekycLeadsListExport: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    from = 0,
    offset = 20,
    order = null,
    direction = null,
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.rekyc_leads_url}?export=true${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${from ? `&from=${from}` : ""}${
        offset ? `&offset=${offset}` : ""
      }${order ? `&order=${order}` : ""}${
        direction ? `&direction=${direction}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => {
          // console.log(data)
          // return data
          return Promise.reject();
        });
      } else {
        return response.blob();
      }
    });
  },
  downloadDocuments: async function (leadId = null) {
    return RestService.get(
      Config.lead_download_documents_url.replace("{id}", leadId),
      {}
    ).then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => {
          // console.log(data)
          // return data
          return Promise.reject();
        });
      } else {
        return response.blob();
      }
    });
  },
  getLeadById: async function (
    id = "",
    payment = null,
    documents = null,
    digio = null,
    followups = null,
    pennydrop = null,
    editable_fields = null
  ) {
    return RestService.get(
      `${Config.get_lead_detail_url.replace("{id}", id)}?${
        payment ? `&payment=${payment}` : ``
      }${documents ? `&documents=${documents}` : ``}${
        digio ? `&digio=${digio}` : ``
      }${followups ? `&followups=${followups}` : ``}${
        pennydrop ? `&pennydrop=${pennydrop}` : ``
      }${editable_fields ? `&editable_fields=${editable_fields}` : ``}`,
      {}
    ).then((response) => response.json());
  },
  getPennydropTransactions: async function (id = "") {
    return RestService.get(
      Config.pennydrop_transactions_url
        .replace("{id}", id)
        .replace("{type}", "pennydrop"),
      {}
    ).then((response) => response.json());
  },
  updateTrackingDetails: async function (body, headers = {}) {
    return RestService.post(
      Config.update_tracking_details_url,
      headers,
      body
    ).then((response) => response.json());
  },
  updateLeadStatus: async function (body, headers = {}) {
    return RestService.post(Config.update_lead_status_url, headers, body).then(
      (response) => response.json()
    );
  },
  moveLeadBackToOngoing: async function (body, headers = {}) {
    return RestService.post(
      Config.move_lead_back_to_ongoing_url,
      headers,
      body
    ).then((response) => response.json());
  },
  editLeadBranch: async function (id, body, headers = {}) {
    return RestService.post(
      Config.edit_lead_branch_url.replace('{id}', id),
      headers,
      body
    ).then((response) => response.json());
  },
  activateLead: async function (body, headers = {}) {
    return RestService.post(Config.mark_lead_activated_url, headers, body).then(
      (response) => response.json()
    );
  },
  checkStatus: async function (id = "") {
    return RestService.get(
      Config.verify_lead_status_url.replace("{id}", id),
      {}
    ).then((response) => response.json());
  },
  refetchPANName: async function (id = "") {
    return RestService.get(
      Config.refetch_pan_name_url.replace("{id}", id),
      {}
    ).then((response) => response.json());
  },
  requestESign: async function (id = "") {
    return RestService.get(
      Config.request_esign_url.replace("{id}", id),
      {}
    ).then((response) => response.json());
  },
  requestIPV: async function (id = "", type) {
    return RestService.get(
      Config.request_ipv_url.replace("{id}", id).replace("{type}", type),
      {}
    ).then((response) => response.json());
  },
  deleteLead: async function (leadId) {
    return RestService.delete(
      `${Config.lead_details_url}?value=${leadId}&field=lead_id`,
      {}
    ).then((response) => response.json())
  },
  updateLead: async function (leadId, body, headers = {}) {
    return RestService.post(
      Config.update_lead_url.replace("{id}", leadId),
      headers,
      body
    ).then((response) => response.json());
  },
  updateLeadDocuments: async function (leadId, body, headers = {}) {
    return RestService.postFormData(
      Config.update_lead_docs_url.replace("{id}", leadId),
      headers,
      body
    ).then((response) => response.json());
  },
  updateAdditionalDocuments: async function (leadId, body, headers = {}) {
    return RestService.postFormData(
      Config.upload_additional_lead_docs_url.replace("{id}", leadId),
      headers,
      body
    ).then((response) => response.json());
  },
  removeAdditionalDocuments: async function (docId, headers = {}) {
    return RestService.delete(
      Config.remove_additional_lead_docs_url.replace("{docId}", docId),
      headers
    ).then((response) => {
      if (response.status === 204) return Promise.resolve();
      return Promise.reject();
    });
  },
  confirmLeadDocuments: async function (leadId, body, headers = {}) {
    return RestService.post(
      Config.confirm_lead_docs_url.replace("{id}", leadId),
      headers,
      body
    ).then((response) => response.json());
  },
  getLeadStats1: async function (
    fromDate = null,
    toDate = null,
    status = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.lead_stats_1_url}?${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getTotalAmountCollected: async function (
    fromDate = null,
    toDate = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.total_amount_collected_url}?${
        fromDate ? `&from_date=${fromDate}` : ""
      }${toDate ? `&to_date=${toDate}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getTAT: async function (
    fromDate = null,
    toDate = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.tat_url}?${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getDemographicStats: async function (
    type,
    fromDate = null,
    toDate = null,
    status = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.lead_stats_demographic_url.replace("{type}", type)}?${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getLeadStatusStats: async function (
    type = undefined,
    state = undefined,
    city = undefined,
    fromDate = null,
    toDate = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.lead_status_stats_url}?${
        type !== null && type !== undefined ? `&referral_type=${type}` : ""
      }${state !== null && state !== undefined ? `&state=${state}` : ""}${
        city !== null && city !== undefined ? `&city=${city}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getTATStats: async function (
    type = undefined,
    state = undefined,
    city = undefined,
    fromDate = null,
    toDate = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.tat_stats_url}?${
        type !== null && type !== undefined ? `&referral_type=${type}` : ""
      }${state !== null && state !== undefined ? `&state=${state}` : ""}${
        city !== null && city !== undefined ? `&city=${city}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getTopLocations: async function (
    type,
    fromDate = null,
    toDate = null,
    status = null,
    count = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.lead_stats_location_url.replace("{type}", type)}?${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${count !== null && count !== undefined ? `&count=${count}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getLeadStepwiseStats: async function (referral_ids = null, signal = null) {
    return RestService.get(
      `${Config.lead_stepwise_count_url}${
        referral_ids !== null && referral_ids !== undefined
          ? `?referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  searchLead: async function (
    filter = "",
    start = 0,
    offset = 20,
    referral_ids = null
  ) {
    return RestService.get(
      `${Config.search_url}?filter=${filter}&from=${start}&offset=${offset}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {}
    ).then((response) => response.json());
  },
  viewLogs: async function (type = null, id = "latest", lead_id = null) {
    return RestService.get(
      `${Config.task_view_logs_url
        .replace("{type}", type)
        .replace("{id}", id)}${lead_id ? `?lead_id=${lead_id}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  taskStats: async function (type = null, rekyc = false) {
    return RestService.get(
      rekyc
        ? Config.rekyc_task_stats_url.replace("{type}", type)
        : Config.task_stats_url.replace("{type}", type),
      {}
    ).then((response) => response.json());
  },
  getTaskInstancesCount: async function (
    type = null,
    fromDate = null,
    toDate = null,
    rekyc = false
  ) {
    return RestService.get(
      rekyc
        ? `${Config.rekyc_task_instances_url.replace(
            "{type}",
            type
          )}?count=true${fromDate ? `&from_date=${fromDate}` : ""}${
            toDate ? `&to_date=${toDate}` : ""
          }`
        : `${Config.task_instances_url.replace("{type}", type)}?count=true${
            fromDate ? `&from_date=${fromDate}` : ""
          }${toDate ? `&to_date=${toDate}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getTaskInstances: async function (
    type = null,
    fromDate = null,
    toDate = null,
    from = 0,
    offset = 20,
    order = null,
    direction = null,
    rekyc = false
  ) {
    return RestService.get(
      rekyc
        ? `${Config.rekyc_task_instances_url.replace("{type}", type)}?${
            fromDate ? `&from_date=${fromDate}` : ""
          }${toDate ? `&to_date=${toDate}` : ""}${from ? `&from=${from}` : ""}${
            offset ? `&offset=${offset}` : ""
          }${order ? `&order=${order}` : ""}${
            direction ? `&direction=${direction}` : ""
          }`
        : `${Config.task_instances_url.replace("{type}", type)}?${
            fromDate ? `&from_date=${fromDate}` : ""
          }${toDate ? `&to_date=${toDate}` : ""}${from ? `&from=${from}` : ""}${
            offset ? `&offset=${offset}` : ""
          }${order ? `&order=${order}` : ""}${
            direction ? `&direction=${direction}` : ""
          }`,
      {}
    ).then((response) => response.json());
  },
  attemptTask: async function (leadId = null, type = null, mode = null) {
    return RestService.get(
      `${Config.attempt_task_url}?type=${type}&mode=${mode}${
        leadId ? `&id=${leadId}` : ""
      }`,
      {}
    ).then((response) => response.json());
  },
  reAttemptTask: async function (leadId = null, type = null) {
    return RestService.get(
      Config.reattempt_task_url.replace("{type}", type).replace("{id}", leadId),
      {}
    ).then((response) => response.json());
  },
  attemptMFTask: async function (leadId = null, type = null, status = -1) {
    return RestService.post(
      `${Config.attempt_mf_task_url}?type=${type}${
        leadId ? `&lead_id=${leadId}` : ""
      }&status=${status}`,
      {}
    ).then((response) => response.json());
  },
  getMoveToStepList: async function () {
    return RestService.get(Config.get_lead_move_to_steps_url, {}).then(
      (response) => response.json()
    );
  },

  // OLD APIs
  removeLead: async function (id, headers = {}) {
    return RestService.delete(
      Config.get_lead_detail_url.replace("{id}", id),
      headers
    );
  },
  getSubSteps: async function (step = "") {
    return RestService.get(
      Config.get_sub_steps_url.replace("{step}", step),
      {}
    ).then((response) => response.json());
  },
  createLead: async function (body, headers = {}) {
    return RestService.postFormData(Config.save_lead_url, headers, body).then(
      (response) => response.json()
    );
  },
  saveStep: async function (lead, body, headers = {}) {
    return RestService.post(
      Config.save_step_url.replace("{lead}", lead),
      headers,
      body
    ).then((response) => response.json());
  },
  resendEmail: async function (lead, step, headers = {}) {
    return RestService.get(
      Config.resend_email_url.replace("{lead}", lead).replace("{step}", step),
      headers
    ).then((response) => response.json());
  },
  addLeadStepDocument: async function (lead, step, body, headers = {}) {
    return RestService.postFormData(
      Config.add_lead_step_documents_url
        .replace("{lead}", lead)
        .replace("{step}", step),
      headers,
      body
    ).then((response) => response.json());
  },
  removeDocument: async function (id, lead, step, headers = {}) {
    return RestService.delete(
      Config.remove_lead_step_documents_url
        .replace("{id}", id)
        .replace("{lead}", lead)
        .replace("{step}", step),
      headers
    );
  },
  getHierarchy: async function () {
    return RestService.get(Config.get_heirarchy_url, {}).then((response) =>
      response.json()
    );
  },
  getHierarchyOptions: async function (type, value) {
    return RestService.get(
      `${Config.get_heirarchy_list_url}?${type ? `&type=${type}` : ""}${
        value ? `&value=${value}` : ""
      }`,
      {}
    ).then((response) => response.json());
  },
  getReKYCLeadById: async function (
    id = "",
    payment = null,
    documents = null,
    digio = null,
    followups = null,
    pennydrop = null,
    editable_fields = null
  ) {
    return RestService.get(
      `${Config.get_rekyc_lead_detail_url.replace("{id}", id)}?${
        payment ? `&payment=${payment}` : ``
      }${documents ? `&documents=${documents}` : ``}${
        digio ? `&digio=${digio}` : ``
      }${followups ? `&followups=${followups}` : ``}${
        pennydrop ? `&pennydrop=${pennydrop}` : ``
      }${editable_fields ? `&editable_fields=${editable_fields}` : ``}`,
      {}
    ).then((response) => response.json());
  },
  updateReKYCTrackingDetails: async function (body, headers = {}) {
    return RestService.post(
      Config.update_rekyc_tracking_details_url,
      headers,
      body
    ).then((response) => response.json());
  },
  updateReKYCLeadStatus: async function (body, headers = {}) {
    return RestService.post(
      Config.update_rekyc_lead_status_url,
      headers,
      body
    ).then((response) => response.json());
  },
  downloadReKYCDocuments: async function (leadId = null) {
    return RestService.get(
      Config.lead_rekyc_download_documents_url.replace("{id}", leadId),
      {}
    ).then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => {
          // console.log(data)
          // return data
          return Promise.reject();
        });
      } else {
        return response.blob();
      }
    });
  },
  activateReKYCLead: async function (body, headers = {}) {
    return RestService.post(
      Config.mark_lead_rekyc_activated_url,
      headers,
      body
    ).then((response) => response.json());
  },
  updateReKYCAdditionalDocuments: async function (leadId, body, headers = {}) {
    return RestService.postFormData(
      Config.upload_rekyc_additional_lead_docs_url.replace("{id}", leadId),
      headers,
      body
    ).then((response) => response.json());
  },
  removeReKYCAdditionalDocuments: async function (docId, headers = {}) {
    return RestService.delete(
      Config.remove_rekyc_additional_lead_docs_url.replace("{docId}", docId),
      headers
    ).then((response) => {
      if (response.status === 204) return Promise.resolve();
      return Promise.reject();
    });
  },
  attemptReKYCTask: async function (
    leadId = null,
    type = null,
    mode = null,
    operation = -1
  ) {
    return RestService.get(
      `${Config.attempt_rekyc_task_url}?type=${type}&mode=${mode}${
        leadId ? `&id=${leadId}` : ""
      }${operation > -1 ? `&insert=${operation}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  reAttemptReKYCTask: async function (leadId = null, type = null) {
    return RestService.get(
      Config.reattempt_rekyc_task_url
        .replace("{type}", type)
        .replace("{id}", leadId),
      {}
    ).then((response) => response.json());
  },
  viewReKYCLogs: async function (type = null, id = "latest", lead_id = null) {
    return RestService.get(
      `${Config.rekyc_task_view_logs_url
        .replace("{type}", type)
        .replace("{id}", id)}${lead_id ? `?lead_id=${lead_id}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  activationTaskReport: async function (
    fromDate = null,
    toDate = null,
    requestType = null,
    reportType = null
  ) {
    return RestService.get(
      `${Config.activation_task_report_url}?export=true${
        fromDate ? `&from_date=${fromDate}` : ""
      }${toDate ? `&to_date=${toDate}` : ""}${
        requestType ? `&request_type=${requestType}` : ""
      }${reportType ? `&report_type=${reportType}` : ""}`,
      {}
    ).then((response) => response.json());
  },

  // Closure
  getClosureLeadsCount: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.closure_leads_url}?count=true${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getClosureLeadsList: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    from = 0,
    offset = 20,
    order = null,
    direction = null,
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.closure_leads_url}?${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${from ? `&from=${from}` : ""}${
        offset ? `&offset=${offset}` : ""
      }${order ? `&order=${order}` : ""}${
        direction ? `&direction=${direction}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => response.json());
  },
  getClosureLeadsListExport: async function (
    status = null,
    fromDate = null,
    toDate = null,
    filter = "",
    from = 0,
    offset = 20,
    order = null,
    direction = null,
    referral_ids = null,
    userFilter = null,
    signType = null
  ) {
    return RestService.get(
      `${Config.closure_leads_url}?export=true${
        status !== null && status !== undefined ? `&status=${status}` : ""
      }${fromDate ? `&from_date=${fromDate}` : ""}${
        toDate ? `&to_date=${toDate}` : ""
      }${filter ? `&filter=${filter}` : ""}${from ? `&from=${from}` : ""}${
        offset ? `&offset=${offset}` : ""
      }${order ? `&order=${order}` : ""}${
        direction ? `&direction=${direction}` : ""
      }${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }${
        userFilter !== null && userFilter !== undefined ? `&${userFilter}` : ""
      }${signType !== null ? `&sign_type=${signType}` : ""}`,
      {}
    ).then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => {
          // console.log(data)
          // return data
          return Promise.reject();
        });
      } else {
        return response.blob();
      }
    });
  },
  getClosureLeadById: async function (
    id = "",
    documents = null,
    editable_fields = null
  ) {
    return RestService.get(
      `${Config.get_closure_lead_detail_url.replace("{id}", id)}?${
        documents ? `&documents=${documents}` : ``
      }${editable_fields ? `&editable_fields=${editable_fields}` : ``}`,
      {}
    ).then((response) => response.json());
  },
  updateClosureTrackingDetails: async function (body, headers = {}) {
    return RestService.post(
      Config.update_closure_tracking_details_url,
      headers,
      body
    ).then((response) => response.json());
  },
  downloadClosureDocuments: async function (leadId = null) {
    return RestService.get(
      Config.lead_closure_download_documents_url.replace("{id}", leadId),
      {}
    ).then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => {
          // console.log(data)
          // return data
          return Promise.reject();
        });
      } else {
        return response.blob();
      }
    });
  },
  updateClosureLeadStatus: async function (body, headers = {}) {
    return RestService.post(
      Config.update_closure_lead_status_url,
      headers,
      body
    ).then((response) => response.json());
  },
  deactivateClosureLead: async function (body, headers = {}) {
    return RestService.post(
      Config.mark_lead_closure_deactivate_url,
      headers,
      body
    ).then((response) => response.json());
  },
  attemptClosureTask: async function (leadId = null, type = null, status = -1) {
    return RestService.post(
      `${Config.attempt_closure_task_url}?type=${type}${
        leadId ? `&lead_id=${leadId}` : ""
      }&status=${status}`,
      {}
    ).then((response) => response.json());
  },
  getAccountsClosedStats: async function (
    fromDate = null,
    toDate = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.closure_stats_accounts_closed_url}?${
        fromDate ? `&from_date=${fromDate}` : ""
      }${toDate ? `&to_date=${toDate}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getClosureReasonsStats: async function (
    fromDate = null,
    toDate = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.closure_reasons_count_url}?${
        fromDate ? `&from_date=${fromDate}` : ""
      }${toDate ? `&to_date=${toDate}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  getClosureStatusStats: async function (
    state = undefined,
    city = undefined,
    fromDate = null,
    toDate = null,
    referral_ids = null,
    signal = null
  ) {
    return RestService.get(
      `${Config.closure_status_stats_url}?${
        state !== null && state !== undefined ? `&state=${state}` : ""
      }${city !== null && city !== undefined ? `&city=${city}` : ""}${
        fromDate ? `&from_date=${fromDate}` : ""
      }${toDate ? `&to_date=${toDate}` : ""}${
        referral_ids !== null && referral_ids !== undefined
          ? `&referral_ids=${referral_ids}`
          : ""
      }`,
      {},
      signal
    ).then((response) => response.json());
  },
  closureApprovalTask: async function (leadId = null, status = -1) {
    return RestService.post(
      `${Config.closure_approval_status_url}?${
        leadId ? `&lead_id=${leadId}` : ""
      }&status=${status}`,
      {}
    ).then((response) => response.json());
  },
};
