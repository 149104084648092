import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import {
  makeStyles,
  Grid,
  Paper,
  Table,
  TablePagination,
  TableRow,
  TableBody,
  Typography,
  Divider,
  Icon,
  ListItemText,
  Tooltip,
  IconButton,
  Fab,
} from "@material-ui/core";
import { blue, red, grey } from "@material-ui/core/colors";
import {
  SIGN_TYPE,
  STATUS,
  STATUS_MAP,
  TABLE_COUNTS,
  USERTYPES,
} from "../Constants";
import { ADD_LEAD_MODULE, RowCountOptions } from "../Config";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import {
  toInt,
  StyledTableCell,
  BootstrapTooltip,
  format_display_fancy_notime,
  getTimeInIST,
  getStatusBadge,
  format,
  format_display_withtime,
} from "../Utils";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SearchBar from "../components/SearchBar";
import AddIcon from "@material-ui/icons/Add";
import queryString from "query-string";
import { LeadService } from "../Services/LeadService";
import moment from "moment";
import SingleDate from "../components/SingleDate";
import { upperCase } from "lodash";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  section: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  qtyField: {
    width: "40%",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      right: "100px",
      width: "10%",
      marginRight: 10,
    },
    right: "22px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: "20px",
  },
  grid: {
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },
    marginTop: "50px",
    marginBottom: "5px",
  },
  icon: {
    padding: "0px",
    [theme.breakpoints.up("md")]: {
      padding: "12px",
    },
  },
  tablecell: {
    fontSize: "16pt",
    padding: theme.spacing(4),
  },
  container: {
    paddingTop: theme.spacing(1),
  },
  redAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: red[500],
  },
  blueAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: blue[500],
  },
  instructions: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    // height: theme.spacing(7),
    float: "right",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      float: "inherit",
      marginLeft: theme.spacing(1),
    },
    marginRight: theme.spacing(4),
  },
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto",
  },
  list: {
    width: "100%",
    height: 250,
    overflow: "auto",
  },
  summary: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  subtitle: {
    fontSize: 18,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectRoot: {
    fontSize: 12,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  autoComplete: {
    // zIndex: 3,
    // [theme.breakpoints.up('md')]: {
    //     marginLeft: theme.spacing(1),
    // },
    marginTop: "0px !important",
  },
}));

export default function ViewLeads(props) {
  const {
    state,
    showSnackBar,
    showConfirmDialog,
    closeConfirmDialog,
    setTitle,
    setLinearLoading,
    showProgressDialog,
    closeProgressDialog,
    openNewLead,
    openLead,
  } = useContext(AppContext);
  const classes = useStyles();
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedSignType, setSelectedSignType] = useState(SIGN_TYPE[0]);
  const [filter, setFilter] = useState("");
  const statuses = STATUS_MAP;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_date");
  const [currentRows, setCurrentRows] = useState([]);
  const [generate, setGenerate] = useState(true);
  const [loadedMaster, setLoadedMaster] = useState(false);
  const [showProgress, setShowProgress] = useState(true);
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"));
  const [endDate, setEndDate] = useState(moment());
  const [hierarchy, setHierarchy] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState({});

  const columns = [
    {
      id: "index",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Sl.No.",
      sortable: false,
    },
    {
      id: "pan_name",
      align: "justify",
      numeric: false,
      disablePadding: false,
      label: "Name",
      sortable: true,
    },
    {
      id: "permanent_state",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "State",
      sortable: true,
    },
    {
      id: "email",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Email",
      sortable: true,
    },
    {
      id: "mobile",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Mobile",
      sortable: true,
    },
    {
      id: "pan",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "PAN",
      sortable: true,
    },
    {
      id: "referral_id",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Referral Id",
      sortable: true,
    },
    {
      id: "branch",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Managed By",
      sortable: true,
    },
    {
      id: "status",
      align: "center",
      numeric: false,
      disablePadding: false,
      label: "Status",
      sortable: true,
    },
    {
      id: "created_date",
      align: "center",
      numeric: false,
      disablePadding: true,
      label: "Created",
      sortable: true,
    },
    {
      id: "action",
      align: "right",
      numeric: true,
      disablePadding: false,
      label: "",
      sortable: false,
    },
  ];

  //Pagination
  const [totalItems, setTotalItems] = useState(0);
  const [numItemsPerPage, setNumItemsPerPage] = useState(
    localStorage.getItem(TABLE_COUNTS.leads_tab_count)
      ? toInt(localStorage.getItem(TABLE_COUNTS.leads_tab_count))
      : RowCountOptions[0]
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [fetchPage, setFetchPage] = useState(false);

  //Dialog
  // const [openChangeStatus, setOpenChangeStatus] = useState(false);
  // const [selectedProperty, setSelectedProperty] = useState({});

  // Show SignType filter
  const showSignFilter = useMemo(() => {
    return selectedStatuses.filter((s) => s.value === 1).length > 0;
  }, [selectedStatuses]);

  const handleChangeRowsPerPage = (event) => {
    setNumItemsPerPage(event.target.value);
    setCurrentPageNumber(0);
    setFetchPage(true);
    localStorage.setItem(TABLE_COUNTS.leads_tab_count, event.target.value);
  };

  const handleChangePage = (event, page) => {
    if (currentPageNumber > page) {
      fetchRow(currentPageNumber - 1);
    } else {
      fetchRow(currentPageNumber + 1);
    }
  };

  const fetchRow = (newCurrentPage) => {
    setCurrentPageNumber(newCurrentPage);
    setFetchPage(true);
  };

  React.useEffect(() => {
    setTitle("View KYC Leads");
    if (props.location && props.location.search) {
      let qString = queryString.parse(props.location.search);
      if (qString.status) {
        let status = statuses.find((s) => s.value === toInt(qString.status));
        if (status) {
          setSelectedStatuses([status]);
        }
      }
    }
    LeadService.getHierarchy()
      .then((data) => {
        if (data && data.result) {
          const hie = data.result;
          let selected = {};
          data.result.forEach((d) => {
            selected[d.value] = null;
          });
          setSelectedHierarchy(selected);

          LeadService.getHierarchyOptions().then(({ result }) => {
            if (result) {
              setHierarchy(
                hie.map((d, i) => ({
                  ...d,
                  options: i === 0 ? result : [],
                }))
              );
            }
          });
        }
      })
      .catch((error) => console.error(error));
    setLoadedMaster(true);
    return () => {
      setTitle("");
    };
  }, [true]);

  React.useEffect(() => {
    if (selectedHierarchy["sales_head"]) {
      const option = selectedHierarchy["sales_head"];
      LeadService.getHierarchyOptions("sales_head", option.value).then(
        ({ result }) => {
          if (result) {
            setHierarchy(
              hierarchy.map((hie) => {
                if (hie.value === "state_head") {
                  hie.options = result;
                }
                return hie;
              })
            );
          }
        }
      );
    }
  }, [selectedHierarchy["sales_head"]]);

  React.useEffect(() => {
    if (selectedHierarchy["state_head"]) {
      const option = selectedHierarchy["state_head"];
      LeadService.getHierarchyOptions("state_head", option.value).then(
        ({ result }) => {
          if (result) {
            setHierarchy(
              hierarchy.map((hie) => {
                if (hie.value === "branch") {
                  hie.options = result;
                }
                return hie;
              })
            );
          }
        }
      );
    }
  }, [selectedHierarchy["state_head"]]);

  React.useEffect(() => {
    if (selectedHierarchy["branch"]) {
      const option = selectedHierarchy["branch"];
      LeadService.getHierarchyOptions("branch", option.value).then(
        ({ result }) => {
          if (result) {
            setHierarchy(
              hierarchy.map((hie) => {
                if (hie.value === "franchisee_rm") {
                  hie.options = result;
                }
                return hie;
              })
            );
          }
        }
      );
    }
  }, [selectedHierarchy["branch"]]);

  const userFilter = () => {
    let userFilter = null;
    if (selectedHierarchy && selectedHierarchy["sales_head"]) {
      userFilter = `sales_head=${selectedHierarchy["sales_head"].value}`;
    }
    if (selectedHierarchy && selectedHierarchy["state_head"]) {
      userFilter = `${userFilter}&state_head=${selectedHierarchy["state_head"].value}`;
    }
    if (selectedHierarchy && selectedHierarchy["branch"]) {
      userFilter = `${userFilter}&branch=${selectedHierarchy["branch"].value}`;
    }
    if (selectedHierarchy && selectedHierarchy["franchisee_rm"]) {
      userFilter = `${userFilter}&${selectedHierarchy["franchisee_rm"].type}=${selectedHierarchy["franchisee_rm"].value}`;
    }
    return userFilter;
  };

  React.useEffect(() => {
    // console.log(generate, loadedMaster)
    if (generate && loadedMaster) {
      setLinearLoading(true);
      setCurrentPageNumber(0);
      // setToolTitle(undefined);
      if (showProgress) {
        showProgressDialog();
      }
      let statuses =
        selectedStatuses && selectedStatuses.length > 0
          ? selectedStatuses.map((s) => s.value).join(",")
          : null;
      const signType =
        showSignFilter && selectedSignType && selectedSignType.value !== null
          ? selectedSignType.value
          : null;
      let fc = filter && filter.length > 0 ? filter : "";
      let referral_ids = null;
      if (
        [USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(
          state.user.EmpUtype
        )
      ) {
        referral_ids = state.user.EmpCode;
      }

      LeadService.getLeadsCount(
        statuses,
        moment(startDate).startOf("d").utc().format(format),
        moment(endDate).endOf("d").utc().format(format),
        fc,
        referral_ids,
        userFilter(),
        signType
      )
        .then((data) => {
          console.log(data);
          setTotalItems(toInt(data.count));
          setFetchPage(true);
          if (toInt(data.count) === 0) {
            setCurrentRows([]);
          }
          setLinearLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLinearLoading(false);
        });
      setGenerate(false);
    }
  }, [generate, loadedMaster]);

  const openDialog = (id) => {
    openLead(id, (shouldRefresh) => {
      if (shouldRefresh) {
        setGenerate(true);
      }
    });
  };

  const handleEdit = (id) => {
    console.log(id);
    openNewLead(id, () => {
      console.log("Refresh");
      setGenerate(true);
    });
  };

  const remove = (id) => {
    showProgressDialog("Removing Lead...");
    LeadService.removeLead(id)
      .then((data) => {
        showSnackBar("Lead Removed", "info");
        closeProgressDialog();
        setGenerate(true);
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            closeConfirmDialog();
          }
        );
        closeProgressDialog();
      });
  };

  const handleRemove = (id) => {
    console.log(id);
    showConfirmDialog(`Confirm Removal of Lead?`, undefined, () => {
      closeConfirmDialog();
      remove(id);
    });
  };

  React.useEffect(() => {
    let rows = [];
    if (fetchPage) {
      setLinearLoading(true);
      let statuses =
        selectedStatuses && selectedStatuses.length > 0
          ? selectedStatuses.map((s) => s.value).join(",")
          : null;
      const signType =
        showSignFilter && selectedSignType && selectedSignType.value !== null
          ? selectedSignType.value
          : null;
      let fc = filter && filter.length > 0 ? filter : "";
      let referral_ids = null;
      if (
        [USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(
          state.user.EmpUtype
        )
      ) {
        referral_ids = state.user.EmpCode;
      }
      LeadService.getLeadsList(
        statuses,
        moment(startDate).startOf("d").utc().format(format),
        moment(endDate).endOf("d").utc().format(format),
        fc,
        currentPageNumber * numItemsPerPage,
        numItemsPerPage,
        orderBy,
        order,
        referral_ids,
        userFilter(),
        signType
      )
        .then((data) => {
          console.log(data);
          if (data && data.leads.length > 0) {
            data.leads.forEach((element, index) => {
              let days = element.created_date
                ? moment().diff(getTimeInIST(element.created_date), "d")
                : 0;
              let tat =
                element.status !== STATUS.ONGOING &&
                element.start_date &&
                element.end_date
                  ? getTimeInIST(element.end_date).diff(
                      getTimeInIST(element.start_date),
                      "days"
                    )
                  : null;
              rows.push(
                <TableRow
                  key={index}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDialog(element.lead_id);
                  }}
                >
                  <StyledTableCell
                    scope="row"
                    align="center"
                    padding="none"
                    style={{ width: 20 }}
                  >
                    {index + 1 + currentPageNumber * numItemsPerPage}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="justify" padding="none">
                    <ListItemText
                      primary={element.pan_name || "-"}
                      secondary={element.client_code || ""}
                      secondaryTypographyProps={{ style: { color: grey[600] } }}
                    />
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="justify" padding="none">
                    {element.permanent_state || "-"}
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding="none">
                    {element.email || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    scope="row"
                    align="center"
                    padding="none"
                    style={{ paddingRight: 16 }}
                  >
                    {element.mobile || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    scope="row"
                    align="center"
                    padding="none"
                    style={{ paddingRight: 16 }}
                  >
                    {element.pan || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    scope="row"
                    align="center"
                    padding="none"
                    style={{ paddingRight: 16 }}
                  >
                    {element.referral_id || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    scope="row"
                    align="center"
                    padding="none"
                    style={{ paddingRight: 16 }}
                  >
                    {element.branch || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    scope="row"
                    align="center"
                    padding="none"
                    style={{ paddingRight: 16 }}
                  >
                    <BootstrapTooltip
                      arrow
                      title={
                        element.status === STATUS.ONGOING ? (
                          element.current_step ? (
                            <React.Fragment>
                              <div>Current Step: {element.current_step}</div>
                            </React.Fragment>
                          ) : (
                            ""
                          )
                        ) : element.status === STATUS.APPROVED ? (
                          <React.Fragment>
                            {element.remarks && (
                              <div>{`Remarks: ${element.remarks}`}</div>
                            )}
                            <div>{`Approved By: ${element.performed_by_name}`}</div>
                            <Typography variant="caption" color="inherit">
                              {element.performed_on
                                ? getTimeInIST(element.performed_on).format(
                                    format_display_withtime
                                  )
                                : ""}
                            </Typography>
                          </React.Fragment>
                        ) : element.status === STATUS.REJECTED ? (
                          <React.Fragment>
                            {element.remarks && (
                              <div>{`Remarks: ${element.remarks}`}</div>
                            )}
                            <div>{`Rejected By: ${element.performed_by_name}`}</div>
                            <div>
                              {element.performed_on
                                ? getTimeInIST(element.performed_on).format(
                                    format_display_withtime
                                  )
                                : ""}
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )
                      }
                    >
                      {getStatusBadge(element.status)}
                    </BootstrapTooltip>
                    <ListItemText
                      primary={
                        element.modified_date
                          ? `Last Updated: ${getTimeInIST(
                              element.modified_date
                            ).format(format_display_fancy_notime)}`
                          : "-"
                      }
                      secondary={
                        tat ? (
                          `TAT: ${tat} days`
                        ) : element.status === STATUS.ONGOING &&
                          element.current_step ? (
                          <Typography variant="caption">
                            Current Step: {element.current_step}
                          </Typography>
                        ) : (
                          ""
                        )
                      }
                      primaryTypographyProps={{ variant: "caption" }}
                      secondaryTypographyProps={{ style: { color: grey[600] } }}
                    />
                  </StyledTableCell>
                  <StyledTableCell scope="row" align="center" padding="none">
                    <ListItemText
                      primary={
                        element.created_date
                          ? getTimeInIST(element.created_date).format(
                              format_display_fancy_notime
                            )
                          : "-"
                      }
                      secondary={`${days} days` || ""}
                      secondaryTypographyProps={{ style: { color: grey[600] } }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    scope="row"
                    align="right"
                    padding="none"
                    style={{ paddingRight: 16 }}
                  >
                    {false && (
                      <BootstrapTooltip arrow title="Remove">
                        <Icon
                          fontSize="small"
                          style={{ marginTop: 8, marginRight: 16 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemove(element.id);
                          }}
                        >
                          delete
                        </Icon>
                      </BootstrapTooltip>
                    )}
                  </StyledTableCell>
                </TableRow>
              );
            });
          }
          setCurrentRows(rows);
          setLinearLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLinearLoading(false);
        })
        .finally(() => {
          if (showProgress) {
            closeProgressDialog();
            setShowProgress(true);
          }
        });
    }
    setFetchPage(false);
  }, [fetchPage]);

  const sortBy = (event, property) => {
    let o = "desc";
    if (orderBy === property && order === "desc") {
      o = "asc";
    }
    setOrder(o);
    setOrderBy(property);
    setGenerate(true);
  };

  const exportReport = () => {
    showProgressDialog("Exporting...");
    let statuses =
      selectedStatuses && selectedStatuses.length > 0
        ? selectedStatuses.map((s) => s.value).join(",")
        : null;
    const signType =
      showSignFilter && selectedSignType && selectedSignType.value !== null
        ? selectedSignType.value
        : null;
    let fc = filter && filter.length > 0 ? filter : "";
    let referral_ids = null;
    if (
      [USERTYPES.FRAN, USERTYPES.RM, USERTYPES.BRANCH].includes(
        state.user.EmpUtype
      )
    ) {
      referral_ids = state.user.EmpCode;
    }
    LeadService.getLeadsListExport(
      statuses,
      moment(startDate).startOf("d").utc().format(format),
      moment(endDate).endOf("d").utc().format(format),
      fc,
      0,
      totalItems,
      orderBy,
      order,
      referral_ids,
      userFilter(),
      signType
    )
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Leads Report.xlsx");
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
        showSnackBar("Failed to Export. Please try again", "error");
      })
      .finally(() => {
        closeProgressDialog();
      });
  };

  const clearSelection = (type) => {
    if (type === "sales_head") {
      setSelectedHierarchy((old) => ({
        ...old,
        state_head: null,
        branch: null,
        franchisee_rm: null,
      }));
      setHierarchy(
        hierarchy.map((d) => ({
          ...d,
          options: ["state_head", "branch", "franchisee_rm"].includes(d.value)
            ? null
            : d.options,
        }))
      );
    } else if (type === "state_head") {
      setSelectedHierarchy((old) => ({
        ...old,
        branch: null,
        franchisee_rm: null,
      }));
      setHierarchy(
        hierarchy.map((d) => ({
          ...d,
          options: ["branch", "franchisee_rm"].includes(d.value)
            ? null
            : d.options,
        }))
      );
    } else if (type === "branch") {
      setSelectedHierarchy((old) => ({ ...old, franchisee_rm: null }));
      setHierarchy(
        hierarchy.map((d) => ({
          ...d,
          options: ["franchisee_rm"].includes(d.value) ? null : d.options,
        }))
      );
    }
    setGenerate(true);
  };

  return (
    <div className="Home">
      <div className="lander">
        <Grid container direction="row" spacing={1} justify="center">
          <Grid item xl={11} lg={12} xs={12}>
            <Paper className={classes.section}>
              <Grid container spacin={1} alignItems="center">
                {hierarchy ? (
                  <Grid
                    item
                    container
                    xs={12}
                    lg={12}
                    alignItems="center"
                    spacing={1}
                  >
                    {hierarchy.map((hierarchy) => (
                      <Grid key={hierarchy.value} item xs={3} lg={2}>
                        <ItemSelection
                          clearable={true}
                          label={hierarchy.label}
                          value={
                            (selectedHierarchy &&
                              selectedHierarchy[hierarchy.value]) ||
                            null
                          }
                          labelTemplate={(option) =>
                            `${option.label} ${option.value}`
                          }
                          groupBy={(option) =>
                            hierarchy.value === "franchisee_rm"
                              ? upperCase(option.type)
                              : null
                          }
                          optionLabel=""
                          renderOption={(option) => (
                            <ListItemText
                              primary={option.label}
                              secondary={option.value}
                              style={{ marginTop: 0, marginBottom: 0 }}
                            />
                          )}
                          disabled={
                            hierarchy.options
                              ? hierarchy.options.length === 0
                              : true
                          }
                          autoCompleteClass={classes.autoComplete}
                          options={hierarchy.options}
                          selected={(s) => {
                            setSelectedHierarchy((old) => ({
                              ...old,
                              [hierarchy.value]: s,
                            }));
                            setShowProgress(false);
                            clearSelection(hierarchy.value);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
                <Grid
                  item
                  container
                  xs={12}
                  lg={12}
                  alignItems="center"
                  spacing={1}
                >
                  {/* <Grid item xs={12} lg={2}>
                                        <ItemSelection 
                                            multiple 
                                            clearable={true} 
                                            label='Type' 
                                            value={selectedType} 
                                            optionLabel='name' 
                                            options={types} 
                                            selected={setSelectedType}/>
                                    </Grid> */}
                  <Grid item xs={3} lg={2}>
                    <ItemSelection
                      multiple
                      clearable={true}
                      label="Status"
                      value={selectedStatuses}
                      optionLabel="label"
                      autoCompleteClass={classes.autoComplete}
                      options={statuses}
                      selected={(s) => {
                        setSelectedStatuses(s);
                        setShowProgress(false);
                        setGenerate(true);
                      }}
                    />
                  </Grid>
                  {showSignFilter ? (
                    <Grid item xs={3} lg={2}>
                      <ItemSelection
                        label="Sign Type"
                        value={selectedSignType}
                        optionLabel="label"
                        autoCompleteClass={classes.autoComplete}
                        options={SIGN_TYPE}
                        selected={(s) => {
                          setSelectedSignType(s);
                          setShowProgress(false);
                          setGenerate(true);
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item>
                    <div style={{ maxWidth: 170 }}>
                      <SingleDate
                        label="From"
                        date={startDate}
                        margin="dense"
                        maxDate={moment()}
                        setDate={(date) => {
                          setStartDate(date);
                        }}
                        onKeyUp={(event) => {
                          event.preventDefault();
                          if (event.key === "Enter") {
                            !generate && setGenerate(true);
                          }
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div style={{ maxWidth: 170, marginLeft: 8 }}>
                      <SingleDate
                        label="To"
                        margin="dense"
                        date={endDate}
                        minDate={startDate}
                        maxDate={moment().add(1, "d")}
                        setDate={(date) => {
                          setEndDate(date);
                        }}
                        onKeyUp={(event) => {
                          event.preventDefault();
                          if (event.key === "Enter") {
                            !generate && setGenerate(true);
                          }
                        }}
                      />
                    </div>
                  </Grid>
                  {/* <Hidden mdDown><Grid item xs={12} lg={2}/></Hidden> */}
                  <Grid item>
                    {loadedMaster && (
                      <div
                        style={{
                          float: "right",
                          marginLeft: 8,
                          marginRight: 8,
                        }}
                      >
                        <SearchBar
                          hint="Search Leads..."
                          searchText={filter}
                          onTextChange={(e) => {
                            setShowProgress(false);
                            setFilter(e.target.value);
                            setGenerate(true);
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item>
                    <Tooltip title="Refresh">
                      <IconButton
                        size="medium"
                        aria-label="Refresh"
                        color="inherit"
                        onClick={() => {
                          !generate && setGenerate(true);
                        }}
                      >
                        <Icon>refresh</Icon>
                      </IconButton>
                    </Tooltip>
                    {state.user &&
                      [
                        USERTYPES.KYC,
                        USERTYPES.HO,
                        USERTYPES.MD,
                        USERTYPES.BRANCH,
                      ].includes(state.user.EmpUtype) && (
                        <Tooltip title="Export">
                          <span>
                            <IconButton
                              disabled={
                                totalItems === 0 ||
                                (state && state.linearLoading)
                              }
                              size="medium"
                              aria-label="Export"
                              color="inherit"
                              onClick={exportReport}
                            >
                              <Icon>save_alt</Icon>
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Divider style={{ marginBottom: 0, marginTop: 10 }} />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div
                    style={{
                      minHeight: 100,
                      overflowY: "auto",
                      display: "block",
                    }}
                  >
                    <Table className={classes.table} stickyHeader>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={sortBy}
                        rows={columns}
                      />
                      <TableBody>{currentRows}</TableBody>
                    </Table>
                  </div>
                  {totalItems === 0 && (
                    <Typography color="error" variant="subtitle2">
                      No Leads to list
                    </Typography>
                  )}
                  <TablePagination
                    rowsPerPageOptions={RowCountOptions}
                    component="div"
                    count={totalItems}
                    rowsPerPage={numItemsPerPage}
                    page={currentPageNumber}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    classes={{ selectRoot: classes.selectRoot }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      {/* <ChangeStatus 
                open={openChangeStatus} 
                id={selectedProperty.id} 
                subtitle={selectedProperty.name}
                fullWidth={true}
                maxWidth={'xs'} 
                refresh={() => {
                    setGenerate(true);
                }} 
                handleClose={() => {
                    setOpenChangeStatus(false);
                    setSelectedProperty({});
                }}/> */}
      {ADD_LEAD_MODULE && !state.linearLoading && (
        <BootstrapTooltip arrow title="Add New Lead">
          <Fab
            variant="extended"
            onClick={() => handleEdit(null)}
            color="primary"
            aria-label="Add"
            className={classes.fab}
          >
            <AddIcon />
            Add New Lead
          </Fab>
        </BootstrapTooltip>
      )}
    </div>
  );
}
