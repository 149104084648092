import { blue, green, red, yellow, grey } from "@material-ui/core/colors";

export const Constants = {
  EXECUTIVE: "EXECUTIVE",
  CUSTOMER: "CUSTOMER",
  LR: "LR",
  CR: "CR",
  IP: "IP",
  ER: "ER",
};

export const USERTYPE_TO_TEXT = {
  'BRANCH': 'Branch / BM',
  'ACE': 'ACE',
  'RMS': 'RMS',
  'FRANSPRT': 'Franchisee Support',
  'TECH': 'Tech Support',
  'SALEHD': 'Sales Head',
  'STATEMG': 'State Manager',
  'CCARE': 'Customer Care',
  'LEAD': 'Lead',
  'KYC': 'KYC',
}

export const USERTYPES = {
  'BRANCH': 'BRANCH',
  'ACE': 'ACE',
  'RMS': 'RMS',
  'FRANSPRT': 'FRANSPRT',
  'TECH': 'TECH',
  'SALEHD': 'SALEHD',
  'STATEMG': 'STATEMG',
  'CCARE': 'CCARE',
  'LEAD': 'LEAD',
  'KYC': 'KYC',
  'MD': 'MD',
  'KYCHO': 'KYCHO',
  'HO': 'HO',
  'FRAN': 'FRAN',
  'RM': 'RM',
}

export const ORG_TYPE = [
  { key: 'Individual' },
  { key: 'Proprietor' },
  { key: 'Partnership' },
  { key: 'Company' },
  { key: 'LLP' },
]

export const STEP_TYPE = {
  approval: 'a',
  email: 'e',
  upload_img: 'upload_img',
  email_custom_file: 'e2',
  receipt: 'r',
  payment_link: 'pay',
  code: 'code',
  code2: 'code2',
  check: 'check',
  inspection: 'insp',
  feedback: 'f',
  sublist: 'sub',
  courier: 'courier',
  welcome: 'welcome',
}

export const FORM_OPTIONS = {
  text: 'text',
  radio: 'radio',
  check: 'check',
  group: 'group',
  static: 'static',
}

export const TABLE_COUNTS = {
  leads_tab_count: "acumen.kyc.leads_tab_count",
  rekyc_leads_tab_count: "acumen.kyc.rekyc_leads_tab_count",
  closure_leads_tab_count: "acumen.kyc.closure_leads_tab_count",
  logs_tab_count: "acumen.kyc.logs_tab_count",
  job_logs_tab_count: "acumen.kyc.job_logs_tab_count",
  pennydrop_tab_count: "acumen.kyc.pennydrop_tab_count",
  coupons_tab_count: "acumen.kyc.coupons_tab_count",
  upload_tab_count: "acumen.kyc.upload_tab_count",
}


export const STATUS = {
  ESIGN_REQUESTED: -3,
  ESIGN_PENDING: -2,
  REJECTED: -1,
  ONGOING: 0,
  COMPLETED: 1, // A.K.A Submitted
  APPROVED: 2,
  ACTIVATED: 3,
};

export const GENDER = {
  F: "Female",
  M: "Male",
  O: "Other",
};

export const STATUS_MAP = [
  {
    type: 'esignrequested',
    label: 'E-Sign Requested',
    value: -3,
    color: red[300],
  },
  {
    type: 'esignpending',
    label: 'E-Sign Pending',
    value: -2,
    color: red[500],
  },
  {
    type: 'rejected',
    label: 'Rejected',
    value: -1,
    color: red[800],
  },
  {
    type: 'ongoing',
    label: 'Ongoing',
    value: 0,
    color: yellow[700],
  },
  {
    type: 'completed',
    label: 'Completed',
    value: 1,
    color: blue[500],
  },
  {
    type: 'approved',
    label: 'Approved',
    value: 2,
    color: green[500],
  },
  {
    type: 'activated',
    label: 'Activated',
    value: 3,
    color: green[800],
    // textColor: grey[800],
  },
]

export const STATUS_MAP_CLOSURE = [
  {
    type: 'esignrequested',
    label: 'E-Sign Requested',
    value: -3,
    color: red[300],
  },
  {
    type: 'esignpending',
    label: 'E-Sign Pending',
    value: -2,
    color: red[500],
  },
  {
    type: 'rejected',
    label: 'Rejected',
    value: -1,
    color: red[800],
  },
  {
    type: 'ongoing',
    label: 'Ongoing',
    value: 0,
    color: yellow[700],
  },
  {
    type: 'completed',
    label: 'Completed',
    value: 1,
    color: blue[500],
  },
  {
    type: 'approved',
    label: 'Approved',
    value: 2,
    color: green[500],
  },
  {
    type: 'deactivated',
    label: 'Deactivated',
    value: 3,
    color: grey[800],
    // textColor: grey[800],
  },
]

export const SIGN_TYPE = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Physical',
    value: 0,
  },
  {
    label: 'E-signed',
    value: 1,
  }
]

export const COUPON_STATUS_MAP = [
  {
    type: 'deleted',
    label: 'Removed',
    value: -1,
    color: red[600],
    textColor: grey[100],
  },
  {
    type: 'rejected',
    label: 'Deactived',
    value: 0,
    color: grey[500],
    textColor: grey[100],
  },
  {
    type: 'activated',
    label: 'Activated',
    value: 1,
    color: green[800],
    // textColor: grey[800],
  },
]

export const DROPDOWN_TYPES = [
  {
    value: "additional_documents",
    label: "Additional Documents",
  },
  {
    value: "annual_income",
    label: "Annual Income",
  },
  {
    value: "approve",
    label: "Approve",
  },
  {
    value: "bank_proof",
    label: "Bank Proof",
  },
  {
    value: "current_address",
    label: "Current Address",
  },
  {
    value: "education",
    label: "Education",
  },
  {
    value: "income_proof",
    label: "Income Proof",
  },
  {
    value: "nominee_relationship",
    label: "Nominee Relationship",
  },
  {
    value: "occupation",
    label: "Occupation",
  },
  {
    value: "reject",
    label: "Reject",
  },
  {
    value: "relationship",
    label: "Relationship",
  },
  {
    value: "trading_experience",
    label: "Trading Experience",
  },
  {
    value: "branch_change",
    label: "Branch Change",
  }
];

export const DEPOSITORY = ['CDSL', 'NSDL']

export const HOLDINGS_OPTIONS = {
  "remat": "Re-materialise",
  "partly": "Partly re-materialise and partly transfer.",
  "transfer": "Transfer to another account",
}

export const SETTLEMENT_OPTIONS = [
  {
    value: "Quarterly",
    label: "Quarterly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  }
]