import React, { useContext } from 'react'
import { makeStyles, Grid, Divider, Typography, Accordion, AccordionSummary, CardHeader, AccordionDetails } from "@material-ui/core";
import { LeadDialogContext } from './ViewLeadDialog';
import { format_display_date, getTimeInIST } from '../Utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: 700
    },
}));

const OtherInformation = () => {

    const classes = useStyles()
    const { lead, expandedOtherDetails, setExpandedOtherDetails } = useContext(LeadDialogContext)

    return (
        <Accordion square expanded={expandedOtherDetails} onChange={() => setExpandedOtherDetails(prev => (!prev))}>
            <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="others-header"
            >
                <CardHeader
                    title={`Other Information`}
                    titleTypographyProps={{ variant: 'h4' }}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16 }}>
                    <Grid container justify="flex-start" spacing={2} alignItems="center">
                        <Grid item xs={12} lg={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>DP ID</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{lead.dp_id || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Client ID</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{lead.client_id || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Referral ID</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{lead.referral_id || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Referral Type</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{lead.referral_label || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Managed By</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{lead.branch || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Created By</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{lead.created_by || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.header}>Start Date</Typography>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="subtitle1">{lead.start_date ? getTimeInIST(lead.start_date).format(format_display_date) : '-'}</Typography>
                        </Grid>
                        {lead.end_date && lead.start_date && (
                            <Grid item container justify="flex-start" spacing={2} alignItems="center">
                                <Grid item xs={12} lg={3}>
                                    <Typography variant="h5" className={classes.header}>TAT</Typography>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Typography variant="subtitle1">{getTimeInIST(lead.end_date).diff(getTimeInIST(lead.start_date), 'days')} days</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </AccordionDetails>
        </Accordion>
    )
};

export default OtherInformation