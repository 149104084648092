import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormHelperText, Grid, ListItemText } from "@material-ui/core";
import { isEmpty, orderBy } from "lodash";
import { LeadService } from "../Services/LeadService";
import { AppContext } from "../AppContextProvider";
import ItemSelection from "./ItemSelection";
import { MasterService } from "../Services/MasterService";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  subheading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: "left",
  },
  dialogContent: {
    paddingTop: 0,
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 14,
  },
}));

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ paddingRight: 24 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EditBranchDialog(props) {
  const { save, close, fullWidth, maxWidth } = props
  const {
    showProgressDialog,
    closeProgressDialog,
    closeConfirmDialog1D,
    showConfirmDialog1D
  } = useContext(AppContext);
  const [hierarchy, setHierarchy] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState({});
  const [dropdodwnOptions, setDropdownOptions] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (props.open) {
      setErrorMessage(undefined);
      setOpen(props.open);
      showProgressDialog('Fetching branches...')
      MasterService.getDropdownOptions("branch_change")
        .then(data => {
          setDropdownOptions(data.branch_change ? orderBy(data.branch_change, "label", "asc") : []);
        })
        .catch((data) => {
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              handleClose();
              closeConfirmDialog1D();
            }
          )
        })
      LeadService.getHierarchy()
        .then((data) => {
          if (data && data.result) {
            const hie = data.result.filter(type => type.value !== 'franchisee_rm');
            let selected = {};
            data.result.filter(type => type.value !== 'franchisee_rm').forEach((d) => {
              selected[d.value] = null;
            });
            setSelectedHierarchy(selected);

            LeadService.getHierarchyOptions().then(({ result }) => {
              if (result) {
                setHierarchy(
                  hie.map((d, i) => ({
                    ...d,
                    options: i === 0 ? result : [],
                  }))
                );
                if (result.length === 1) {
                  setSelectedHierarchy(old => ({ ...old, sales_head: result[0] }))
                }
              }
            });
          }
        })
        .catch((error) => console.error(error))
        .finally(() => closeProgressDialog());
    } else {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (selectedHierarchy["sales_head"]) {
      const option = selectedHierarchy["sales_head"];
      LeadService.getHierarchyOptions("sales_head", option.value).then(
        ({ result }) => {
          if (result) {
            setHierarchy(
              hierarchy.map((hie) => {
                if (hie.value === "state_head") {
                  hie.options = result;
                }
                return hie;
              })
            );
            if (result.length === 1) {
              setSelectedHierarchy(old => ({ ...old, state_head: result[0] }))
            }
          }
        }
      );
    }
  }, [selectedHierarchy["sales_head"]]);

  useEffect(() => {
    if (selectedHierarchy["state_head"]) {
      const option = selectedHierarchy["state_head"];
      LeadService.getHierarchyOptions("state_head", option.value).then(
        ({ result }) => {
          if (result) {
            setHierarchy(
              hierarchy.map((hie) => {
                if (hie.value === "branch") {
                  hie.options = result;
                }
                return hie;
              })
            );
            if (result.length === 1) {
              setSelectedHierarchy(old => ({ ...old, branch: result[0] }))
            }
          }
        }
      );
    }
  }, [selectedHierarchy["state_head"]]);

  const handleClose = () => {
    close();
    setSelectedHierarchy({});
    setOpen(false);
    setErrorMessage(undefined);
  };

  const handleSave = () => {
    if (isEmpty(selectedHierarchy.branch) || isEmpty(selectedHierarchy.reason)) {
      setErrorMessage("Select Branch, Reason and continue");
    } else {
      save({
        state_head: selectedHierarchy.state_head.value,
        sales_head: selectedHierarchy.sales_head.value,
        branch: selectedHierarchy.branch.value,
        reason: selectedHierarchy.reason.value,
      })
    }
  };

  const clearSelection = (type) => {
    if (type === "sales_head") {
      setSelectedHierarchy((old) => ({
        ...old,
        state_head: null,
        branch: null,
      }));
      setHierarchy(
        hierarchy.map((d) => ({
          ...d,
          options: ["state_head", "branch"].includes(d.value)
            ? null
            : d.options,
        }))
      );
    } else if (type === "state_head") {
      setSelectedHierarchy((old) => ({
        ...old,
        branch: null,
      }));
      setHierarchy(
        hierarchy.map((d) => ({
          ...d,
          options: ["branch"].includes(d.value)
            ? null
            : d.options,
        }))
      );
    }
  };

  return (
    <Dialog
      fullWidth={fullWidth !== undefined ? fullWidth : true}
      maxWidth={maxWidth !== undefined ? maxWidth : "lg"}
      onClose={(event, reason) =>
        reason !== "backdropClick" &&
        reason !== "escapeKeyDown" &&
        handleClose()
      }
      aria-labelledby="customized-dialog-title"
      disableEscapeKeyDown
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        classes={classes}
      >
        <Typography variant="h6" component="div">
          Edit Branch
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ paddingTop: 10 }}>
        {hierarchy ? (
          <Grid
            container
            alignItems="center"
            spacing={1}
          >
            {hierarchy.map((hierarchy) => (
              <Grid key={hierarchy.value} item xs={12}>
                <ItemSelection
                  required
                  label={hierarchy.label}
                  value={
                    (selectedHierarchy &&
                      selectedHierarchy[hierarchy.value]) ||
                    null
                  }
                  labelTemplate={(option) =>
                    `${option.label} ${option.value}`
                  }
                  optionLabel=""
                  renderOption={(option) => (
                    <ListItemText
                      primary={option.label}
                      secondary={option.value}
                      style={{ marginTop: 0, marginBottom: 0 }}
                    />
                  )}
                  disabled={
                    hierarchy.options
                      ? hierarchy.options.length === 0
                      : true
                  }
                  autoCompleteClass={classes.autoComplete}
                  options={hierarchy.options}
                  selected={(s) => {
                    setSelectedHierarchy((old) => ({
                      ...old,
                      [hierarchy.value]: s,
                    }));
                    clearSelection(hierarchy.value);
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <ItemSelection
                required={true}
                label='Reason'
                value={selectedHierarchy.reason || null}
                optionLabel='label'
                options={dropdodwnOptions}
                autoCompleteClass={classes.autoComplete}
                selected={(option) => {
                  setSelectedHierarchy((old) => ({
                    ...old,
                    reason: option,
                  }));
                }} />
            </Grid>
          </Grid>
        ) : null}
        {errorMessage ? (
          <FormHelperText error>{errorMessage}</FormHelperText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button autoFocus onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditBranchDialog.prototype = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.bool,
};
